<template>
  <div class="schedaUtente" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md8 lg5>
          <v-toolbar color="secondary">
              <v-toolbar-title class="white--text">
                  Scheda Utente
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn v-if="ruolo != 'Tecnico'" small icon @click="remove_user()" color="white">
                <v-icon small>fas fa-trash</v-icon>
              </v-btn>
              <v-btn v-if="token == tokenUtente || (ruolo != 'Tecnico' || (ruolo == 'Tecnico' && permesso == 'Cliente'))" small icon @click="isEditing = !isEditing" color="white" class="ml-3">
                <v-icon small>fas fa-edit</v-icon>
              </v-btn>
              <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card outlined>
              <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                  <v-container>
                      <v-layout column>
                          <v-select
                              :items="['Baggi','LaCelle']"
                              v-model="appartenenzaCliente"
                              v-if="permesso == 'Cliente' && (ruolo == 'Master' || ruolo == 'Tecnico')"
                              :readonly="!isEditing"
                              required
                              filled
                              hide-details="auto"
                              :rules="requiredRules"
                              label="Appartenenza"
                              class="mb-2"
                          ></v-select>
                          <v-text-field
                              label="Nominativo 1*"
                              v-model="nominativo1"
                              required
                              :readonly="!isEditing"
                              :rules="nominativo1Rules"
                          ></v-text-field>
                          <v-text-field
                              label="Nominativo 2"
                              v-model="nominativo2"
                              required
                              :readonly="!isEditing"
                              :rules="nominativo2Rules"
                          ></v-text-field>
                          <v-text-field
                              label="Email*"
                              v-model="email"
                              required
                              :readonly="!isEditing"
                              :rules="emailRules"
                          ></v-text-field>
                          <v-text-field
                              label="Telefono"
                              v-model="telefono"
                              required
                              :readonly="!isEditing"
                              :rules="telefonoRules"
                          ></v-text-field>
                          <v-text-field
                              label="Codice Fiscale"
                              v-model="codiceFiscale"
                              :readonly="!isEditing"
                              :rules="codiceFiscaleRules"
                          ></v-text-field>
                          <v-text-field
                              label="Partita Iva"
                              v-model="partitaIva"
                              :readonly="!isEditing"
                              :rules="partitaIvaRules"
                          ></v-text-field>
                          <v-textarea
                              label="Note"
                              v-model="note"
                              outlined
                              :readonly="!isEditing"
                              auto-grow
                              rows="1"
                          ></v-textarea>
                          <v-checkbox 
                              label="Crea Accesso" 
                              dense 
                              v-model="accesso" 
                              class="mt-0 pt-0"
                              :readonly="!isEditing"></v-checkbox>
                          <v-text-field
                              v-if="accesso"
                              :readonly="!isEditing"
                              autocomplete="off"
                              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPwd ? 'text' : 'password'"
                              label="Password"
                              class="input-group--focused"
                              v-model="password"
                              @click:append="showPwd = !showPwd"
                          ></v-text-field>
                          <v-select
                              :items="lista_permessi"
                              v-model="permesso"
                              required
                              filled
                              :rules="requiredRules"
                              :readonly="!isEditing"
                              label="Permessi*"
                              class="mb-0"
                          ></v-select>
                          <v-expansion-panels v-if="!permesso ? false : permesso == 'Cliente'"  :value="0">
                            <v-expansion-panel>
                              <v-expansion-panel-header disable-icon-rotate>
                                Stabilimenti
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="white mr-2"
                                  plain
                                  x-small
                                  right
                                  @click="modalNewStabilimento = true;"
                                  max-width="32px"
                                >
                                  <v-icon style="font-size:20px" color="black">
                                    fas fa-plus
                                  </v-icon>
                                </v-btn>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list
                                  subheader
                                  two-line
                                >
                                  <v-list-item
                                    v-for="stabilimento of stabilimenti"
                                    :key="stabilimento._id"
                                  >
                                    <v-list-item-avatar>
                                      <v-icon
                                        class="grey lighten-1"
                                        dark
                                      >
                                        fas fa-building
                                      </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>{{ stabilimento.indirizzo }}</v-list-item-title>

                                      <v-list-item-subtitle>{{ stabilimento.note }}</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                      <v-btn icon :to="'/schedaStabilimento?_id='+stabilimento._id">
                                        <v-icon color="grey lighten-1">fas fa-eye</v-icon>
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                      </v-layout>
                  </v-container>
              </v-form>
              <v-card-actions>
                <v-spacer/>
                  <v-col cols="4" class="pt-0"> 
                    <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && !formValid && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn> 
                  </v-col>
                <v-spacer/>
              </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      width="500"
      v-model="modalNewStabilimento"
    >
      <NewStabilimento v-if="modalNewStabilimento" @refreshStabilimenti="refreshListStabilimenti" :id_cliente="_id"/>
    </v-dialog>
  </div>
</template>

<script>
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiUtenti from '@/js/pages/utenti';
import { emailRules, signupPasswordRules, nominativo1Rules, nominativo2Rules, telefonoRules, requiredRules, codiceFiscaleRules, partitaIvaRules } from '@/js/validationRules';
const NewStabilimento = () => import('@/components/NewStabilimento');

export default {
  name: 'schedaUtente',
  data() {
    return {
      token: this.$cookies.get('token'),
      ruolo: this.$store.state.ruolo,
      _id: this.$route.query._id,
      isEditing: false,
      appartenenzaCliente: 'LaCelle',
      email: '',
      telefono: '',
      nominativo1: '',
      nominativo2: '',
      codiceFiscale: '',
      partitaIva: '',
      password: '',
      showPwd: false,
      note: '',
      formValid: false,
      emailRules: emailRules,
      passwordRules: signupPasswordRules,
      nominativo1Rules: nominativo1Rules,
      nominativo2Rules: nominativo2Rules,
      codiceFiscaleRules: codiceFiscaleRules,
      partitaIvaRules: partitaIvaRules,
      telefonoRules: telefonoRules,
      requiredRules: requiredRules,
      accesso: false,
      loading: false,
      lista_permessi: ['Master', 'Tecnico', 'Partner', 'Cliente'],
      permesso: undefined,
      stabilimenti: [],
      utente: {},
      modalNewStabilimento: false,
      tokenUtente: '',
    }
  },
  watch:{
    ruolo() {
      if(this.ruolo == 'Partner') {
        this.appartenenzaCliente = 'Baggi';
        this.lista_permessi = ['Cliente'];
      } else if(this.ruolo == 'Tecnico') this.lista_permessi = ['Cliente'];
    }
  },
  components: {
    NewStabilimento
  },
  async created() {
    if(!this._id) this._id = this.$route.query._id;
    let utente = await apiUtenti.getUtenteFromId(this._id);
    this.tokenUtente = utente.token || '';
    this.nominativo1 = utente.nominativo1 || '';
    this.nominativo2 = utente.nominativo2 || '';
    this.email = utente.email || '';
    this.partitaIva = utente.partitaIva || '';
    this.codiceFiscale = utente.codiceFiscale || '';
    this.telefono = utente.telefono || '';
    this.note = utente.note || '';
    this.permesso = utente.ruolo || '';
    this.appartenenzaCliente = utente.appartenenza || 'LaCelle';
    this.accesso = utente.accesso || false;
    this.stabilimenti = await apiStabilimenti.getAll(this.token, this._id);
    if(this.ruolo == 'Partner') {
      this.appartenenzaCliente = 'Baggi';
      this.lista_permessi = ['Cliente'];
    } else if(this.ruolo == 'Tecnico') this.lista_permessi = ['Cliente'];
  },
  methods: {
    async submit(){
      if(this.isEditing){
        this.loading = true;
        if(
          this.$refs.form_data.validate() &&
          this.formValid
        ){
          let data = {
            _id: this._id,
            accesso: this.accesso,
            appartenenza: this.appartenenzaCliente,
            email: this.email,
            telefono: this.telefono,
            nominativo1: this.nominativo1,
            nominativo2: this.nominativo2,
            codiceFiscale: this.codiceFiscale,
            partitaIva: this.partitaIva,
            password: this.password,
            note: this.note,
            ruolo: this.permesso,
          };
          await apiUtenti.updateOne(this.token, data);
          this.$router.push('/listUtenti');
        }
        this.loading = false;
      } else this.$router.back();
    },
    async remove_user(){
      if(confirm("Eliminare l'utente?")){
        let data = { _id: this._id };
        await apiUtenti.deleteOne(this.token, data);
        this.$router.push('/listUtenti');
      }
    },
    async refreshListStabilimenti(stabilimento){
      this.modalNewStabilimento = false;
      if(stabilimento) this.stabilimenti.push(stabilimento);
    }
  },
}
</script>
